// extracted by mini-css-extract-plugin
export var moreText = "index-module--moreText--B03Rc";
export var navBtn = "index-module--navBtn--jcLQv";
export var niceContent = "index-module--niceContent--9NfTU";
export var niceDesc = "index-module--niceDesc--Z0ZIX";
export var niceIcon = "index-module--niceIcon--GzY7w";
export var niceItem = "index-module--niceItem--ct7Lo";
export var niceList = "index-module--niceList--73pky";
export var niceTitle = "index-module--niceTitle--lhwmj";
export var plan = "index-module--plan--xQbum";
export var planItem = "index-module--planItem--Uzi0V";
export var planItemContent = "index-module--planItemContent--bjmRI";
export var planItemTitle = "index-module--planItemTitle--Mfm2u";
export var planItemWrap = "index-module--planItemWrap--UyXZZ";
export var priceContainer = "index-module--priceContainer--4W5hR";
export var priceCover = "index-module--priceCover--wo3cB";
export var priceItem = "index-module--priceItem--RuPyR";
export var priceItemText = "index-module--priceItemText--kyrtK";
export var priceItemTitle = "index-module--priceItemTitle--9gDRM";
export var priceItemUl = "index-module--priceItemUl--Qayl3";
export var priceItemUnit = "index-module--priceItemUnit--WhlMn";
export var tabContent = "index-module--tabContent--83HbX";
export var tabContentItem = "index-module--tabContentItem--0m-mw";
export var tabContentItemChild = "index-module--tabContentItemChild--cbNcM";
export var tabContentTitle = "index-module--tabContentTitle--C-6UK";
export var tabIcon = "index-module--tabIcon--9X-R9";
export var tabItem = "index-module--tabItem--m3gRy";
export var tabItemActive = "index-module--tabItemActive--i7W63";
export var tabList = "index-module--tabList--bs9zB";