import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import Banner from '../../assets/images/cloud-public-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from './index.module.less';
import Button from '../../components/Button';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { Link } from 'gatsby';

const TAB_LIST = [
  {
    title: '数据上云',
    content: [
      {
        name: '本地数据上云',
        desc: '系统基于IPFS架构研发，能够实现千节点，EB级扩展，能够帮助您轻松通过Internet传输数据，为您带来极致的数据上云体验',
      },
      {
        name: '第三方数据上云',
        desc: '支持数据从第三方存储平台快速备份或迁徙到土星云，土星云有更高的数据存储性价比和更快的传输速度',
      },
      {
        name: '多种写入API',
        desc: '根据不同写入场景，土星云提供丰富的写入API：简单上传、表单上传、断点续传、追加上传、第三方资源抓取等',
      },
      {
        name: '多种接口兼容',
        desc: '土星云兼容了AWS S3接口，支持众多基于AWS S3开发的应用程序、SDK及第三方服务在不修改代码同时支持更多基于AWS S3的开源应用',
      },
    ],
  },
  {
    title: '云端数据管理',
    content: [
      {
        name: '数据监控',
        desc: '多维度展示数据使用量信息，用户可随时查看自己的存储用量以及数据使用情况',
      },
      {
        name: '子账号',
        desc: '支持创建多个子账号，实现数据访问隔离，满足访客用户临时访问对象存储场景，提高数据安全性',
      },
      {
        name: '增删改查',
        desc: '通过控制台或API接口，用户可以对数据进行上传、下载、删除、查询替换等操作，并对存储桶和对象进行处理',
      },
      {
        name: '加密存储',
        desc: '用户数据经过分片和加密处理后，存储在不同区域存储节点，除数据所有者外，即使后台管理员也无法读取完整数据',
      },
    ],
  },
  {
    title: '数据分发',
    content: [
      {
        name: '数据联动',
        desc: '土星云支持数据与其他云计算或应用实时联动，包括CDN加速，数字图片处理，音视频转码等',
      },
      {
        name: '数据池',
        desc: '土星云存储可作为大数据计算、分析和应用调用的数据池，为数据分析与计算提供数据源',
      },
    ],
  },
];
const PLAN_LIST = [
  {
    title: '物联网数据云存储',
    content:
      '随着物联网监控视频的普及，监控产生的数据需大量上传至云端存储，现有的视频云存储面临上传慢、访问慢、流量贵等问题。土星云基于IPFS架构，数据通过千万级节点同时传输分布式存储在不同终端，上传、下载、访问速度快，同时仅收取数据存储费用，是目前市面上性价比最优的物联网视频云存储服务',
  },
  {
    title: 'SaaS应用数据云存储',
    content:
      '地图、打车、外卖、租房、交友等Saas应用有大量底层数据和实时交互数据，土星云存储根据SaaS应用数据云存储解决方案，数据访问毫秒级延时，同时完美兼容第三方平台云应用',
  },
  {
    title: '教育行业数据云存储',
    content:
      '远程教育的普及，每天产生大量直播和录屏的音频、互动、视频需要云端存储，土星云存储根据远程教育访问量大，回放多的特点，提供远程视频数据云存储解决方案，在低延迟的基础上实现数据的高并发，同时仅收取数据存储费用，数据访问、调用等永久免费',
  },
  {
    title: '医疗数据云存储',
    content:
      '随着医联体和医共体近几年快速发展，云影像、远程问诊、医生远程教育等产生大量图片、影音，图片等数据，土星云存储根据医疗行业数据安全性高，存储周期长、数据后期访问频次低的特点，提供医疗行业数据云存储解决方案，在医疗数据安全和存储成本方面做到极致',
  },
  {
    title: 'VR数据云存储',
    content:
      'VR已经深入到教育、游戏、工业、影音等多个方面，同时VR也产生大量实时数据需要云端存储。土星云存储根据VR行业实时数据量大、并发访问量高的数据特点，提供VR数据云存储解决方案，可实现VR数据快速上传、访问、调用、交互。',
  },
  {
    title: '影视频数据云存储',
    content:
      '视频特效公司、视频后期制作公司、直播平台等大量的影视频数据云存储需求，土星云存储根据影视频公司和直播平台的数据存储特点，提供视频数据云存储解决方案，数据上传、下载可达宽带理论值，超大容量数据也能轻松上云',
  },
  {
    title: '其它',
    content:
      '在工业互联网、车联网、自动驾驶、三维仿真等领域，土星云存储均有完备的数据云存储解决方案，土星云存储无存储容量上线，可存储任意数量和形式的数据',
  },
];

function getSlidesPerView() {
  // if (window.innerWidth <= 900) {
  //   return 1;
  // }
  return 3;
}

export default function CloudPublicPage() {
  const [tabActive, setTabActive] = useState(0);
  const activeContent = TAB_LIST[tabActive].content;

  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="海纳数据，不凡潜力"
        desc="采用分布式集群等技术，构建的可弹性伸缩云存储服务，基于10多年的技术沉淀，保护您在云上的应用系统和数据安全，为您提供超高效率比的高性能云服务器。"
      />
      <SectionWrap
        title="海量云端数据存储服务"
        desc="稳定、安全、高效的云存储服务"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div>
          <div className={styles.tabList}>
            {TAB_LIST.map((item, index) => {
              let itemClassName = styles.tabItem;
              let imgIcon = `/images/cloud-public-tab-icon-${index + 1}`;
              if (tabActive === index) {
                itemClassName += ' ' + styles.tabItemActive;
                imgIcon += '-active';
              }
              return (
                <div
                  className={itemClassName}
                  key={index}
                  onClick={() => {
                    setTabActive(index);
                  }}>
                  <img className={styles.tabIcon} src={`${imgIcon}.png`} alt="" />
                  <div>{item.title}</div>
                </div>
              );
            })}
          </div>
          <div className={styles.tabContent}>
            {activeContent.map((item) => {
              return (
                <div className={styles.tabContentItem} key={item.name}>
                  <div className={styles.tabContentItemChild}>
                    <div className={styles.tabContentTitle}>{item.name}</div>
                    <div>{item.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品优势" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/images/cloud-public-nice-1.png" alt="" />
            </div>
            <div className={styles.niceTitle}>可靠性高</div>
            <div className={styles.niceDesc}>99.9999999999%</div>
            <div className={styles.niceContent}>
              提供12个9的数据持久性，同时土星云存储将数据加密切片存储在多台独立设备上，天然防DDOS、CC攻击
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/images/cloud-public-nice-2.png" alt="" />
            </div>
            <div className={styles.niceTitle}>并发量高</div>
            <div className={styles.niceDesc}>千万级TPS</div>
            <div className={styles.niceContent}>
              基于去中心化架构，千万级节点可以同时对外数据传输，无需担心网络阻塞
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img width={55} height={55} src="/images/cloud-public-nice-3.png" alt="" />
            </div>
            <div className={styles.niceTitle}>传输快</div>
            <div className={styles.niceDesc}>3GB/S</div>
            <div className={styles.niceContent}>
              数据上传、下载速度快，土星云存储基于IPFS技术研发，支持多点下载、全局访问，能大幅提升数据传输速度
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/images/cloud-public-nice-4.png" alt="" />
            </div>
            <div className={styles.niceTitle}>接入便捷</div>
            <div className={styles.niceDesc}>支持多协议访问</div>
            <div className={styles.niceContent}>
              土星云提供了多种API，兼容AWS
              S3接口，支持更多在服务不修改代码前提下接入，数据可无缝迁移，免除高昂的迁移成本和接入成本
            </div>
          </div>
        </div>
      </SectionWrap>

      <SectionWrap title="多行业数据云存储方案" desc="精准适配多种应用场景数据存储需求">
        <div className={styles.plan}>
          <Swiper
            spaceBetween={0}
            slidesPerView={getSlidesPerView()}
            centeredSlides
            loop
            autoplay={true}
            navigation
            modules={[Navigation, Autoplay]}>
            {PLAN_LIST.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className={styles.planItemWrap}>
                    <div
                      className={styles.planItem}
                      style={{
                        backgroundImage: `url("/images/cloud-public-plan-${index + 1}.jpg")`,
                      }}>
                      <div className={styles.planItemContent}>{item.content}</div>
                      <div className={styles.planItemTitle}>{item.title}</div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </SectionWrap>

      <SectionWrap
        title="价格最优的云存储服务"
        desc="仅收取存储费用，流量、请求等永久免费"
        background="#f6fafd">
        <div className={styles.priceContainer}>
          <div className={styles.priceCover}>
            <StaticImage width={800} src="../../assets/images/cloud-public-price-1.png" alt="" />
          </div>
          <div className={styles.priceItem}>
            <div className={styles.priceItemTitle}>请求费用</div>
            <div className={styles.priceItemUnit}>0元/万次</div>
            <ul className={styles.priceItemUl}>
              <li>读请求次数</li>
              <li>写请求次数</li>
            </ul>
            <span className={styles.priceItemText}>永久免费</span>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.priceItemTitle}>流量费用</div>
            <div className={styles.priceItemUnit}>0元/GB</div>
            <ul className={styles.priceItemUl}>
              <li>外网数据流出</li>
              <li>跨区域数据流出</li>
            </ul>
            <span className={styles.priceItemText}>永久免费</span>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.priceItemTitle}>存储费用</div>
            <div className={styles.priceItemUnit}>
              <span style={{ color: 'red' }}>0.06元/GB/月</span>
              {/* <a href="" className={styles.moreText}>
                了解更多
              </a> */}
            </div>
            <ul className={styles.priceItemUl}>
              <li>
                适用类型 <a style={{ marginLeft: '1em', cursor: 'auto' }}>全场景文件</a>
              </li>
              <li>
                访问特点 <a style={{ marginLeft: '1em', cursor: 'auto' }}>实时访问</a>
              </li>
            </ul>
            <div className={styles.priceItemText}>
              <Link to="/probation">
                <Button>申请试用</Button>
              </Link>
            </div>
          </div>
        </div>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="公有云" />;
}
